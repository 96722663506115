@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "futura-bold";
  src: local("futura-bold"),
   url("./assets/font/Futura-Bol.ttf") format("truetype");
  font-weight: bold;
 }
 
 @font-face {
     font-family: "futura";
     src: local("futura"),
      url("./assets/font/Futura-Boo.ttf") format("truetype");
     font-weight: normal;
    }


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.color-flash {
  animation: flashColors 0.5s infinite; /* Apply the animation */
}

@keyframes flashColors {
  0% {
      background-color: #CB0B2E;
  }
  50% {
      background-color: white;
  }
  100% {
      background-color: #e62346; /* Back to blue at the end */
  }
}

.parent-container {
  border-radius: 50%;
  transform: rotate(40deg);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 690px;
  height: 690px;
  margin-left: -255px;

  > div {
    height: 625px;
    width: 625px;
    max-height: unset;
    max-width: unset;
  }
  @media (max-width: 768px) {
    width: 500px;
    height: 500px;
    > div {
      height: 450px;
      width: 450px;
    }
  }
}


.spinner-background-image{
  width: 702px;
  height:702px;
  max-width: unset;
}
@media (max-width: 768px) {
  .spinner-background-image {
    width: 508px;
    height:508px;
  }
}
.custom-input::placeholder {
  color: rgb(53, 46, 46);
}

/* @keyframes lightingEffect {
  0% {
      transform: rotate(0deg);
  }
  50% {
    transform: rotate(12deg);
  }
  100% {
    transform: rotate(0deg);
  }
} */




